import { RecentUpdatesTextDto, WelcomeTextDto } from 'api-client';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { dashboardApi } from 'common/api/api';

export const useDashboardApi = () => {
  const { aroundApi } = useApi();

  return {
    getWelcomeText: (config?: ApiConfig): Promise<WelcomeTextDto> => aroundApi(dashboardApi.getWelcomeText(), config),
    updateWelcomeText: (welcomeTextDto: WelcomeTextDto, config?: ApiConfig): Promise<WelcomeTextDto> =>
      aroundApi(dashboardApi.updateWelcomeText(welcomeTextDto), config),
    getRecentUpdates: (config?: ApiConfig): Promise<RecentUpdatesTextDto> => aroundApi(dashboardApi.getRecentUpdates(), config),
    updateRecentUpdates: (recentUpdatesTextDto: RecentUpdatesTextDto, config?: ApiConfig): Promise<RecentUpdatesTextDto> =>
      aroundApi(dashboardApi.updateRecentUpdates(recentUpdatesTextDto), config),
  };
};
