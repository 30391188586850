import { useEffect, useState } from 'react';

import { RecentUpdatesTextDto } from 'api-client';

import { Form } from 'ui-library';

import Page from 'common/layout/Page';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { useDashboardApi } from 'dashboard/DashboardApiHooks';

import RecentUpdatesTextMaintenanceForm from './RecentUpdatesTextMaintenanceForm';

const RecentUpdatesTextMaintenancePage: React.FC = () => {
  const { getRecentUpdates, updateRecentUpdates } = useDashboardApi();
  const [recentUpdateText, setRecentUpdateText] = useState<RecentUpdatesTextDto>();

  const retrieveRecentUpdatesText = () => getRecentUpdates({ showLoadingSpinner: true }).then(setRecentUpdateText);

  useEffect(() => {
    retrieveRecentUpdatesText();
  }, []);

  const onSubmit = (values: RecentUpdatesTextDto) => {
    updateRecentUpdates(values).then(retrieveRecentUpdatesText);
  };

  return (
    <Page title="Recent Updates Text Maintenance">
      {recentUpdateText && (
        <Form id="maintain-template-form" initialValues={recentUpdateText} handleSubmit={onSubmit} mode="VIEW" canEdit>
          <SectionHeading title="Recent Updates Text Maintenance" />
          <RecentUpdatesTextMaintenanceForm />
        </Form>
      )}
    </Page>
  );
};

export default RecentUpdatesTextMaintenancePage;
