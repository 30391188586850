import React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from 'common/auth/ProtectedRoute';

import RecentUpdatesTextMaintenancePage from './RecentUpdatesTextMaintenancePage';

const RecentUpdatesTextRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ProtectedRoute path={`${path}/maintenance`} component={RecentUpdatesTextMaintenancePage} role={['ROLE_SYSTEM_ADMINISTRATOR']} />
      </Switch>
    </>
  );
};

export const useRecentUpdatesTextNavigation = () => {
  const history = useHistory();

  return {
    navigateToRecentUpdatesTextMaintenance: () => history.push('/ui/app/recent-updates-text/maintenance'),
  };
};

export default RecentUpdatesTextRouter;
