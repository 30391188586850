import { DefaultButton, mergeStyles, PrimaryButton } from '@fluentui/react';
import { FormikContextType, useFormikContext } from 'formik';
import { PropertyName } from 'lodash';
import { uniqueId } from 'lodash';
import * as React from 'react';

import { Grid } from 'ui-library';

import BackButton from 'common/controls/buttons/BackButton';

import InlineButton from '../controls/buttons/InlineButton';
import { dirtyFormConfirmStrategy, neverConfirmStrategy, withConfirmOnClick } from '../controls/inputs/ConfirmOnClick';
import { FormMode } from './Form';
import { useForm } from './FormHook';

export interface FormButtonsProps {
  onEditClick: () => void;
  onCancelEdit: () => void;
  hideCancel?: boolean;
  canEdit?: boolean;
  top?: boolean;
  hideSubmit?: boolean;
  disableSubmit?: boolean;
  hideBack?: boolean;
  inModal?: boolean;
  disabledSubmitWhenNoDirty?: boolean;
  submitButtonText?: string;
  submitButtonId?: string;
  additionalButtons?: (mode: FormMode) => FormButton[] | undefined;
  onSubmit?: (formikContext: FormikContextType<unknown>) => void;
  onBack?: () => void;
  ignoreFieldsValidation?: PropertyName[];
  noMargin?: boolean;
}

export type FormButton = {
  id: string;
  text: string;
  onClick: (formikContext: FormikContextType<unknown>) => void;
  shouldConfirm?: boolean;
  disabled?: boolean;
  isPrimary?: boolean;
  disabledWhenNoDirty?: boolean;
  disabledWhenInvalid?: boolean;
  title?: string;
};

const FormButtons: React.FC<FormButtonsProps> = (props) => {
  const { mode } = useForm();
  const className = mergeStyles(
    props.noMargin
      ? {}
      : {
          marginTop: props.top ? '10px' : '20px',
          marginBottom: props.top ? '0px' : props.inModal ? '0px' : '30px',
        },
  );
  const buttonsGroupClass = `ms-Grid-col ${!props.hideBack ? 'ms-sm8' : 'ms-sm12'}`;

  const renderAdditionalButtons = () =>
    props.additionalButtons ? (
      props
        .additionalButtons(mode)
        ?.map((formButton: FormButton, idx: number) => DecorateFormButton(formButton, idx, props.top, props.disabledSubmitWhenNoDirty))
    ) : (
      <></>
    );
  const showEditButton = props.canEdit && mode === 'VIEW';

  return (
    <div className={className} id={uniqueId(createId('form-buttons', props.top))}>
      <Grid.Row>
        {!props.hideBack && (
          <Grid.Col sm={4} md={4} lg={4} style={{ display: 'inline-block' }}>
            <BackBtn id={createId('back-button', props.top)} onBack={props.onBack} />
          </Grid.Col>
        )}
        <div className={buttonsGroupClass} style={{ textAlign: 'right', display: 'inline-block' }}>
          {renderAdditionalButtons()}
          {showEditButton && <EditButton {...props} />}
          <ActionButtons {...props} />
        </div>
      </Grid.Row>
    </div>
  );
};

const ActionButtons = (props: FormButtonsProps) => {
  const formikContext = useFormikContext();
  const { mode } = useForm();
  const handleSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit(formikContext);
    } else {
      formikContext.submitForm();
    }
  };
  const buttonId = props.submitButtonId ? props.submitButtonId : 'submit-button';
  const showCancel = mode === 'EDIT' && !props.hideCancel;
  const showSubmit = (mode === 'EDIT' || mode === 'CREATE') && !props.hideSubmit;

  return (
    <>
      {showCancel && <CancelButton {...props} />}
      {showSubmit && (
        <SubmitButton
          id={createId(buttonId, props.top)}
          text={props.submitButtonText}
          onSubmit={handleSubmit}
          disabledSubmitWhenNoDirty={props.disabledSubmitWhenNoDirty}
          disableSubmit={props.disableSubmit}
        />
      )}
    </>
  );
};

const EditButton = ({ onEditClick: onEdit }: FormButtonsProps) => <InlineButton text="Edit" onClick={onEdit} />;

const CancelButton = ({ onCancelEdit, ignoreFieldsValidation }: FormButtonsProps) => {
  const formikContext = useFormikContext();
  const { mode } = useForm();

  return withConfirmOnClick(
    <DefaultButton text="Cancel" onClick={onCancelEdit} />,
    mode !== 'SEARCH' && mode !== 'VIEW' ? dirtyFormConfirmStrategy(formikContext, ignoreFieldsValidation) : neverConfirmStrategy,
    formikContext,
  );
};

export const SubmitButton = (props: {
  id: string;
  onSubmit: () => void;
  text?: string;
  disabledSubmitWhenNoDirty?: boolean;
  disableSubmit?: boolean;
}) => {
  const formikContext = useFormikContext();
  const { mode } = useForm();
  const isDisabled = mode === 'EDIT' && (formikContext.isSubmitting || props.disabledSubmitWhenNoDirty === true ? !formikContext.dirty : false);

  return (
    <PrimaryButton
      text={props.text || 'Save'}
      disabled={isDisabled || props.disableSubmit}
      onClick={props.onSubmit}
      style={{ marginLeft: '10px' }}
      id={uniqueId(props.id)}
      data-automation-id={props.id}
    />
  );
};

const BackBtn = (props: { id: string; onBack?: () => void }) => {
  const formikContext = useFormikContext();
  const { mode } = useForm();

  return withConfirmOnClick(
    <BackButton {...props} />,
    mode !== 'SEARCH' && mode !== 'VIEW' ? dirtyFormConfirmStrategy(formikContext) : neverConfirmStrategy,
    formikContext,
  );
};

const DecorateFormButton = (formButton: FormButton, idx: number, top?: boolean, disabledSubmitWhenNoDirty?: boolean) => {
  const id = uniqueId(createId(formButton.id, top));
  const formikContext = useFormikContext();
  const { mode } = useForm();
  const isDisabledPrimary = mode === 'EDIT' && (formikContext.isSubmitting || disabledSubmitWhenNoDirty ? !formikContext.dirty : false);
  const isDisabledDefault =
    mode === 'EDIT' &&
    (formikContext.isSubmitting ||
      (formButton.disabledWhenNoDirty ? !formikContext.dirty : false) ||
      (formButton.disabledWhenInvalid ? !formikContext.isValid : false));

  const handleClick = () => {
    formButton.onClick(formikContext);
  };
  const button = formButton.isPrimary ? (
    <PrimaryButton {...formButton} id={id} data-automation-id={createId(formButton.id, top)} disabled={isDisabledPrimary} onClick={handleClick} />
  ) : (
    <DefaultButton
      {...formButton}
      id={id}
      data-automation-id={createId(formButton.id, top)}
      onClick={handleClick}
      disabled={isDisabledDefault || formButton.disabled}
    />
  );

  return (
    <span style={{ marginRight: '10px' }} key={idx}>
      {formButton.shouldConfirm
        ? withConfirmOnClick(
            button,
            mode !== 'SEARCH' && mode !== 'VIEW' ? dirtyFormConfirmStrategy(formikContext) : neverConfirmStrategy,
            formikContext,
          )
        : button}
    </span>
  );
};

export const createId = (id: string, top?: boolean) => (top ? `${id}-top` : `${id}-bottom`);

export default FormButtons;
